@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

/************** colors ************/
body {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #374151;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #9CA3AF;
  border-radius: 6px;
}

.completeLogWrap {
  width: 100%;
  float: left;
  height: 100vh;
  background-color: #fff;
  position: relative;
  font-family: "Poppins";
}
.completeLogWrap span.goLink {
  position: absolute;
  right: 34px;
  top: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #9F9F9F;
  z-index: 99;
}
.completeLogWrap span.goLink a {
  font-weight: 500;
  color: #454897;
  text-decoration: none;
  margin: 0 0 0 9px;
}
.completeLogWrap::after {
  content: "";
  width: 435px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #AAADFF;
  background-image: url(../images/loginRocket.svg);
  background-size: 360px;
  background-repeat: no-repeat;
  background-position: right bottom;
}
.completeLogWrap .logContainWrap {
  width: calc(100% - 435px);
  float: right;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.completeLogWrap .logContainWrap .logmain {
  width: 550px;
  float: left;
  min-height: 100px;
  position: relative;
}
.completeLogWrap .logContainWrap .logmain h1 {
  width: 100%;
  float: left;
  margin: 0 0 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #000000;
}
.completeLogWrap .logContainWrap .logmain h1 small {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #9F9F9F;
  margin: 10px 0 0 0;
}
.completeLogWrap .logContainWrap .logmain .mainLogForm {
  width: 100%;
  float: left;
  margin: 35px 0 0 0;
}
.completeLogWrap .logContainWrap .logmain .mainLogForm .userfld {
  width: 100%;
  float: left;
  height: 65px;
  background-color: #F2F3FF;
  border-radius: 8px;
  border: 1px solid #F2F3FF;
  font-family: "Poppins";
  padding: 0 0 0 69px;
  font-size: 14px;
  font-weight: 300;
  background-image: url(../images/userIcon.svg);
  background-repeat: no-repeat;
  background-position: 26px 50%;
  background-size: 25px;
  margin: 0 0 18px 0;
  outline: none;
}
.completeLogWrap .logContainWrap .logmain .mainLogForm .userfld::-moz-placeholder {
  color: #A4A5C1;
}
.completeLogWrap .logContainWrap .logmain .mainLogForm .userfld::placeholder {
  color: #A4A5C1;
}
.completeLogWrap .logContainWrap .logmain .mainLogForm input[type=password] {
  background-image: url(../images/lockicon.svg);
}
.completeLogWrap .logContainWrap .logmain .mainLogForm input[type=email] {
  background-image: url(../images/envelope.svg);
}
.completeLogWrap .logContainWrap .logmain .mainLogForm .subBttn {
  border: 0;
  outline: none;
  background-color: #86c264;
  font-size: 16px;
  color: #fff;
  font-family: "Poppins";
  font-weight: 500;
  border-radius: 8px;
  padding: 13px 38px;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.14);
}
.completeLogWrap .logContainWrap .logmain.errorAlrt {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}
@keyframes shake {
  0% {
    transform: translate(0px, 0px);
  }
  20% {
    transform: translate(-30px, 0px);
  }
  40% {
    transform: translate(30px, 0px);
  }
  60% {
    transform: translate(-30px, 0px);
  }
  80% {
    transform: translate(30px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.messageContain {
  width: 550px;
  left: calc(50% - 275px);
  background-color: #fff;
  color: #6d6d6d;
  position: fixed;
  top: 10px;
  font-size: 13px;
  font-weight: 500;
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.05);
  padding: 13px 27px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 400ms cubic-bezier(0.4, 0.5, 0.3, 2) 150ms;
  font-family: "Poppins";
  z-index: 9999;
}
.messageContain.animate {
  top: 60px;
  opacity: 1;
}
.messageContain svg.mainIcon {
  margin: 0 10px 0 0px;
  font-size: 17px;
}
.messageContain.successMsg {
  background-color: #bceca2;
  color: #608f45;
}
.messageContain .closeMsg {
  position: absolute;
  right: 19px;
  top: 13px;
  cursor: pointer;
  opacity: 0.5;
}

.adminPanelMainWrap {
  width: 100%;
  float: left;
  min-height: 100vh;
  position: relative;
  background-color: #ECF0F3;
}
.adminPanelMainWrap aside {
  width: 250px;
  height: calc(100vh - 50px);
  position: fixed;
  left: 25px;
  top: 25px;
  background-color: #454897;
  border-radius: 15px;
  padding: 90px 40px;
  font-family: "Ubuntu";
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  overflow: auto;
}
.adminPanelMainWrap aside::-webkit-scrollbar {
  display: none;
}
.adminPanelMainWrap aside .sidebarLinks {
  width: 100%;
  float: left;
  position: relative;
  display: flex;
  flex-flow: column;
}
.adminPanelMainWrap aside .sidebarLinks span {
  width: 100%;
  float: left;
}
.adminPanelMainWrap aside .sidebarLinks span a {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  font-weight: 300;
  opacity: 0.6;
  padding: 13px 0;
  margin: 0;
}
.adminPanelMainWrap aside .sidebarLinks span a img {
  width: 16px;
  margin: 0px 21px 0 0px;
}
.adminPanelMainWrap aside .sidebarLinks span a.active {
  opacity: 1;
}

.rightSideContents {
  width: 250px;
  right: 50px;
  top: 25px;
  position: fixed;
}
.rightSideContents .adminProfile {
  width: 100%;
  float: left;
  height: unset;
  background: #EAEFF2;
  border: 1px solid #DFE8EE;
  box-shadow: 10px 10px 38px rgba(209, 217, 230, 0.71), -9px -14.42px 41.17px #FFFFFF;
  border-radius: 16px;
  min-height: 200px;
  padding: 51px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-family: "Poppins";
}
.rightSideContents .adminProfile picture {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 3px solid #fff;
  background-color: #AAADFF;
  background-image: url(../images/userPlaceholder.svg);
  background-size: 60px;
  background-position: center;
  background-repeat: no-repeat;
}
.rightSideContents .adminProfile h4 {
  width: 100%;
  float: left;
  text-align: center;
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  letter-spacing: 0.01em;
  color: #717C98;
}
.rightSideContents .adminProfile h4 small {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #8993A5;
  margin: 3px 0 0 0;
}
.rightSideContents .adminProfile a {
  float: left;
  width: 129px;
  height: 45px;
  background: #F37171;
  box-shadow: 0px 4px 12px rgba(243, 113, 113, 0.34);
  border-radius: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #EFD4D4;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  margin: 15px 0 0 0;
}
.rightSideContents .customersListing {
  width: 100%;
  float: left;
  margin: 30px 0 0 0;
}
.rightSideContents .customersListing h4 {
  width: 100%;
  float: left;
  margin: 0 0 0 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 33px;
  color: #717C98;
}
.rightSideContents .customersListing .mainListing {
  width: 100%;
  float: left;
  margin: 5px 0 0 0;
}
.rightSideContents .customersListing .mainListing .customerListBox {
  width: 100%;
  float: left;
  margin: 10px 0 0 0;
  padding: 15px;
  background: #EAEFF2;
  border: 1px solid #DFE8EE;
  box-shadow: 10px 10px 38px rgba(209, 217, 230, 0.71), -9px -14.42px 41.17px #FFFFFF;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.rightSideContents .customersListing .mainListing .customerListBox picture {
  width: 60px;
  height: 57px;
  float: left;
  border-radius: 8px;
  background-color: #AAADFF;
}
.rightSideContents .customersListing .mainListing .customerListBox p {
  float: left;
  margin: 0 0 0 15px;
  width: 122px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: unset;
  color: #556874;
}

.maindbBody {
  width: calc(100% - 564px);
  float: left;
  margin-left: 270px;
  margin-top: 35px;
  padding: 0 35px;
}
.maindbBody header.adminHeader {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D9E2E8;
  padding: 0 0 20px 0;
}
.maindbBody header.adminHeader span.logoWrap {
  width: 180px;
  float: left;
}
.maindbBody header.adminHeader span.logoWrap img {
  width: 100%;
}
.maindbBody header.adminHeader .bellIcon {
  float: left;
}
.maindbBody header.adminHeader .bellIcon svg {
  font-size: 22px;
  color: #717c98;
}

.completeProductWrapper {
  width: 100%;
  float: left;
  margin: 25px 0 0 0;
}
.completeProductWrapper .addProductWrap {
  width: 100%;
  float: left;
  margin: 25px 0 0 0;
}
.completeProductWrapper .addProductWrap .usrInpt {
  width: 100%;
  float: left;
  font-family: "Poppins";
  background: #eaeff2;
  border: 1px solid #d2d6d9;
  padding: 15px 25px;
  outline: none;
  margin: 8px 0;
  border-radius: 9px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
}
.completeProductWrapper .addProductWrap .textLabel {
  width: 100%;
  float: left;
  font-family: "Poppins";
  color: #f37171;
  font-weight: 500;
  font-size: 14px;
  margin: 10px 0 -5px 0;
  padding: 0 4px;
}
.completeProductWrapper .addProductWrap .typePhotoInput {
  width: 100%;
  float: left;
  font-family: "Poppins";
  background: #eaeff2;
  border: 1px solid #d2d6d9;
  padding: 15px 25px;
  padding-left: 55px;
  outline: none;
  margin: 8px 0;
  border-radius: 9px;
  color: #000;
  font-weight: 400;
  position: relative;
  background-image: url(../images/iconImg.svg);
  background-repeat: no-repeat;
  background-position: 20px 50%;
  background-size: 21px;
}
.completeProductWrapper .addProductWrap .typePhotoInput b {
  font-weight: normal;
  font-size: 14px;
  float: left;
  width: 88%;
  word-break: break-all;
}
.completeProductWrapper .addProductWrap .typePhotoInput input[type=file] {
  display: none;
}
.completeProductWrapper .addProductWrap .typePhotoInput picture {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 7px;
  top: 5px;
  border-radius: 8px;
  overflow: hidden;
}
.completeProductWrapper .addProductWrap .typePhotoInput picture img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.completeProductWrapper .addProductWrap button {
  float: left;
  background-color: #86c264;
  color: #fff;
  font-family: "Poppins";
  border: 0;
  padding: 12px 22px;
  margin: 10px 0 12px 0;
  border-radius: 5px;
  cursor: pointer;
}
.completeProductWrapper .productListWrap {
  width: 100%;
  float: left;
  margin: 30px 0 0 0;
  display: flex;
  flex-flow: column-reverse;
}
.completeProductWrapper .productListWrap .plBox {
  width: 100%;
  float: left;
  background: #EAEFF2;
  border: 1px solid #DFE8EE;
  box-shadow: 10px 10px 38px rgba(209, 217, 230, 0.71), -9px -14.42px 41.17px #FFFFFF;
  border-radius: 16px;
  padding: 20px 35px;
  display: flex;
  align-items: center;
  margin: 13px 0;
  position: relative;
  overflow: hidden;
}
.completeProductWrapper .productListWrap .plBox picture {
  width: 100px;
  height: 90px;
  float: left;
}
.completeProductWrapper .productListWrap .plBox picture img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.completeProductWrapper .productListWrap .plBox h4 {
  width: 185px;
  float: left;
  font-family: "Poppins";
  margin: 0 0 0 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #3D4E58;
}
.completeProductWrapper .productListWrap .plBox h4 small {
  width: 100%;
  float: left;
  color: #1C87EA;
  margin: 6px 0 0 0;
  font-size: 13px;
}
.completeProductWrapper .productListWrap .plBox h5.price {
  float: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #FB7272;
  margin: 0 0 0 40px;
}
.completeProductWrapper .productListWrap .plBox h5.price.hide {
  display: none;
}
.completeProductWrapper .productListWrap .plBox h5.price.show {
  display: block;
}
.completeProductWrapper .productListWrap .plBox .delete {
  background-color: transparent;
  border: 0;
  outline: none;
  position: absolute;
  right: 32px;
  font-size: 20px;
  color: #c0ccd3;
  cursor: pointer;
}
.completeProductWrapper .productListWrap .plBox a.edit {
  position: absolute;
  right: 82px;
  color: #c0ccd3;
  font-size: 19px;
}
.completeProductWrapper .productListWrap .plBox .deleteplBoxcontain {
  position: absolute;
  z-index: 99;
  right: -280px;
  background: #fff;
  padding: 13px 23px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  transition: all 500ms cubic-bezier(0.4, 0.5, 0.3, 1.25) 100ms;
}
.completeProductWrapper .productListWrap .plBox .deleteplBoxcontain p {
  width: auto;
  float: left;
  margin: 0 22px 0 0px;
  font-size: 15px;
}
.completeProductWrapper .productListWrap .plBox .deleteplBoxcontain button {
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 8px 12px;
  margin: 0 3px;
  font-family: "Poppins";
}
.completeProductWrapper .productListWrap .plBox .deleteplBoxcontain button.sureDelete {
  background-color: #86c264;
  color: #fff;
}
.completeProductWrapper .productListWrap .plBox .deleteplBoxcontain.active {
  right: 20px;
}

.cpwHead {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cpwHead h2 {
  float: left;
  margin: 0;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000000;
}
.cpwHead .headbttnsWrp {
  float: left;
  display: flex;
  align-items: center;
}
.cpwHead .headbttnsWrp a.addBttn {
  float: left;
  width: 40px;
  height: 40px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: #717c98;
}
.cpwHead .headbttnsWrp .searchBox {
  float: left;
  margin: 0 10px 0 0;
}
.cpwHead .headbttnsWrp .searchBox .searchInpt {
  width: 300px;
  float: left;
  border: 0;
  outline: none;
  background-color: #fff;
  font-family: "Poppins";
  height: 45px;
  padding: 0 0px 0 50px;
  border-radius: 25px;
  background-image: url(../images/searchIcon.svg);
  background-size: 23px;
  background-repeat: no-repeat;
  background-position: 14px 50%;
}

.caregoriesListing {
  width: 100%;
  float: left;
  margin: 30px 0 0 0;
}
.caregoriesListing .cpwHead {
  margin: 0 0 25px 0;
}
.caregoriesListing .catBox {
  width: 100%;
  float: left;
  background-color: #fff;
  margin: 6px 6px;
  padding: 12px 85px 12px 20px;
  position: relative;
}
.caregoriesListing .catBox h4 {
  width: 100%;
  float: left;
  margin: 0 0;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  color: #000;
  text-transform: capitalize;
}
.caregoriesListing .catBox h4 b {
  font-weight: 500;
}
.caregoriesListing .catBox h4 small {
  width: 100%;
  float: left;
  margin: 10px 0 0 0;
  color: #9d9d9d;
}
.caregoriesListing .catBox .delete {
  position: absolute;
  right: 11px;
  width: auto;
  padding: 0 0;
  background: #fff;
  border: 0;
  top: 15px;
  color: #b5bdc3;
  cursor: pointer;
}
.caregoriesListing .catBox .editIcon {
  position: absolute;
  right: 41px;
  top: 13px;
}
.caregoriesListing .catBox .editIcon a {
  color: #b5bdc3;
}

.catBoxDeleteWrap {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
}
.catBoxDeleteWrap .confBox {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border: 1px solid #8993a5;
  padding: 45px 0;
  border-radius: 12px;
  width: 320px;
}
.catBoxDeleteWrap .confBox h4 {
  width: 100%;
  float: left;
  margin: 0;
}
.catBoxDeleteWrap .confBox span {
  width: 100%;
  float: left;
  margin: 20px 0 0 0;
}
.catBoxDeleteWrap .confBox span button {
  display: inline-block;
  width: 75px;
  height: 38px;
  border: 0;
  margin: 0 6px;
  cursor: pointer;
  outline: none !important;
}
.catBoxDeleteWrap .confBox span button.sureToDelete {
  background-color: #86c264;
}

.addCategories {
  width: 100%;
  float: left;
  margin: 30px 0 0 0;
}
.addCategories .addCatForm {
  width: 100%;
  float: left;
  margin: 50px 0 0 0;
}
.addCategories .addCatForm .addCategory {
  width: 39%;
  float: left;
  font-family: "Poppins";
  background: #eaeff2;
  border: 1px solid #d2d6d9;
  padding: 15px 25px;
  outline: none;
  border-radius: 9px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  margin: 0 8px 0 0px;
}
.addCategories .addCatForm button {
  font-family: "Poppins";
  background: #86c264;
  border: 1px solid #86c264;
  padding: 15px 25px;
  outline: none;
  border-radius: 9px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  float: left;
  cursor: pointer;
}

.catWithSubCatBox {
  width: 100%;
  float: left;
  margin: 15px 0;
}
.catWithSubCatBox h4 {
  width: 100%;
  float: left;
  font-family: "Poppins";
  margin: 0;
}
.catWithSubCatBox span.subCatbox {
  float: left;
  background-color: #fff;
  font-family: "Ubuntu";
  margin: 10px 10px 0 0;
  padding: 12px 65px 12px 16px;
  border-radius: 5px;
  font-size: 14px;
  color: #67727a;
  font-weight: 400;
  position: relative;
}
.catWithSubCatBox span.subCatbox button.delSubCat {
  padding: 0;
  border: 0;
  background-color: #fff;
  cursor: pointer;
  position: absolute;
  right: 17px;
  color: #909699;
  outline: none;
}

.editCatBox {
  width: 100%;
  float: left;
  margin: 50px 0 0 0;
}
.editCatBox .editCat {
  width: 300px;
  float: left;
  font-family: "Poppins";
  background: #eaeff2;
  border: 1px solid #d2d6d9;
  padding: 15px 25px;
  outline: none;
  border-radius: 9px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  margin: 0 8px 0 0px;
}
.editCatBox button {
  font-family: "Poppins";
  background: #86c264;
  border: 1px solid #86c264;
  padding: 15px 25px;
  outline: none;
  border-radius: 9px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  float: left;
  cursor: pointer;
}

.subcatLIstMainWrap {
  width: 100%;
  float: left;
}
.subcatLIstMainWrap .subCatBoxContain {
  width: 100%;
  float: left;
  margin: 20px 0 0 0;
}
.subcatLIstMainWrap .subCatBox {
  width: 100%;
  float: left;
  background-color: #fff;
  margin: 6px 0px;
  padding: 12px 85px 12px 20px;
  position: relative;
  font-family: "Ubuntu";
  font-size: 14px;
  position: relative;
}
.subcatLIstMainWrap .subCatBox .deleteIcon {
  padding: 0;
  position: absolute;
  right: 15px;
  top: 13px;
  border: 0;
  background: #fff;
  color: #bfc0cd;
  cursor: pointer;
}
.subcatLIstMainWrap .subCatBox .editSubcat {
  padding: 0;
  position: absolute;
  right: 40px;
  top: 13px;
  border: 0;
  background: #fff;
  color: #bfc0cd;
  cursor: pointer;
}

.changeCategory {
  width: 35%;
  float: left;
  font-family: "Poppins";
  background: #eaeff2;
  border: 1px solid #d2d6d9;
  padding: 15px 25px;
  outline: none;
  border-radius: 9px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  margin: 0 8px 0 0px;
  text-transform: capitalize;
}

.storyListWrapAdmin {
  width: 100%;
  float: left;
  margin: 12px 0 0 0;
  display: flex;
  flex-flow: row wrap;
  gap: 3.5%;
}
.storyListWrapAdmin .storyBox {
  width: 31%;
  height: 350px;
  background-color: #fff;
  margin: 20px 0 0 0;
  position: relative;
}
.storyListWrapAdmin .storyBox video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.storyListWrapAdmin .storyBox video::-webkit-media-controls-volume-slider {
  display: none;
}
.storyListWrapAdmin .storyBox video::-webkit-media-controls-mute-button {
  display: none;
}
.storyListWrapAdmin .storyBox video::-webkit-media-controls-fullscreen-button {
  display: none;
}
.storyListWrapAdmin .storyBox video::-webkit-media-controls-play-button {
  display: none;
}
.storyListWrapAdmin .storyBox a, .storyListWrapAdmin .storyBox button {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  padding: 13px 10px;
  background: #fff;
  border-radius: 0px 0px 0px 17px;
  color: #008cff;
}
.storyListWrapAdmin .storyBox a.delete, .storyListWrapAdmin .storyBox button.delete {
  right: 37px;
}
.storyListWrapAdmin .storyBox a.edit, .storyListWrapAdmin .storyBox button.edit {
  border-radius: 0;
}

.OfferBttn {
  margin-left: 12px !important;
  background: #1c87ea !important;
}

.offerBoxWrap {
  width: 100%;
  float: left;
  margin: 20px 0;
  padding: 35px;
  background: #EAEFF2;
  border: 1px solid #DFE8EE;
  box-shadow: 10px 10px 38px rgba(209, 217, 230, 0.71), -9px -14.42px 41.17px #ffffff;
  border-radius: 16px;
}
.offerBoxWrap p {
  float: left;
  font-family: "Ubuntu";
  cursor: pointer;
  color: #f37171;
  font-weight: 600;
  font-size: 14px;
  margin: 12px 0 0 0;
}
.offerBoxWrap h2 {
  width: 100%;
  float: left;
  font-family: "Poppins";
  margin: 0 0 4px 0;
  font-weight: 400;
  font-size: 19px;
}
.offerBoxWrap .offerImg {
  width: 100%;
  float: left;
  position: relative;
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.offerBoxWrap .offerImg input {
  display: none;
}
.offerBoxWrap .offerImg img {
  width: 90px;
  height: 90px;
  -o-object-fit: contain;
     object-fit: contain;
  background-image: url(../images/noImage.png);
  background-repeat: no-repeat;
  background-size: 45px;
  background-position: center;
  margin: 0 13px 0 0px;
  border-radius: 100%;
  border: 1px solid #1C87EA;
}
.offerBoxWrap .offerImg b {
  font-family: "Ubuntu";
  color: #1C87EA;
  cursor: pointer;
}

.completeOrderListWrap {
  width: 100%;
  float: left;
  margin: 30px 0;
}
.completeOrderListWrap .ordersListBox {
  width: 100%;
  float: left;
  background-color: #fff;
  padding: 20px 20px 60px 20px;
  margin: 8px 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
}
.completeOrderListWrap .ordersListBox h4 {
  max-width: 300px;
  float: left;
  font-family: "Poppins";
  margin: 0px 9px 0 0px;
  font-size: 14px;
  font-weight: 500;
}
.completeOrderListWrap .ordersListBox h4 small {
  width: 100%;
  float: left;
  color: #0040ff;
  margin: 5px 0 0 0;
}
.completeOrderListWrap .ordersListBox h4 strong {
  width: 100%;
  float: left;
  font-weight: 400;
  margin: 9px 0 0 0;
  color: #ababab;
}
.completeOrderListWrap .ordersListBox h5 {
  float: left;
  margin: 0 0 0 0;
  font-family: "Poppins";
  color: #6bab47;
  font-weight: 500;
}
.completeOrderListWrap .ordersListBox h5 small {
  width: 100%;
  float: left;
  color: #f77;
}
.completeOrderListWrap .ordersListBox a.bttnMain {
  position: absolute;
  right: 24px;
  text-decoration: none;
  color: #ffa800;
  font-size: 23px;
}
.completeOrderListWrap .ordersListBox .orderTime {
  width: 100%;
  text-align: center;
  font-family: "Ubuntu";
  position: absolute;
  bottom: 16px;
  font-size: 12px;
  color: #989898;
  border-top: 1px solid #ddd;
  right: 0;
  padding: 14px 0 0 0;
  font-weight: 500;
}

.orderDetailsCompleteBlog {
  width: 100%;
  float: left;
  margin: 25px 0 0 0;
}
.orderDetailsCompleteBlog .detailsPart {
  width: 100%;
  float: left;
}
.orderDetailsCompleteBlog .detailsPart p {
  width: 100%;
  float: left;
  margin: 3px 0;
  font-family: "Poppins";
  display: flex;
  align-items: center;
  font-size: 14px;
}
.orderDetailsCompleteBlog .detailsPart p svg {
  margin: 0 11px 0 0px;
  color: #686cc4;
  width: 19px;
}

.productList {
  width: 100%;
  float: left;
  margin: 20px 0 0px 0;
}
.productList .productBox {
  width: 100%;
  float: left;
  padding: 20px 20px;
  background-color: #fff;
  margin: 6px 0;
  display: flex;
  align-items: center;
  position: relative;
}
.productList .productBox picture {
  width: 60px;
  height: 60px;
  float: left;
}
.productList .productBox picture img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.productList .productBox h4 {
  float: left;
  margin: 0 0 0 45px;
  font-family: "Ubuntu";
  display: flex;
  flex-flow: column;
  font-size: 17px;
  font-weight: 400;
}
.productList .productBox h4 small {
  margin: 10px 0px 0 0;
  font-weight: 500;
  color: #86c264;
  font-size: 17px;
}
.productList .productBox a.LiBttn {
  position: absolute;
  right: 36px;
  font-size: 24px;
  color: #ffa800;
}

.priceSection {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins";
  margin: 10px 0 35px 0;
  padding: 0 16px;
}
.priceSection p {
  font-size: 14px;
  font-weight: 500;
}

#react-select-3-listbox {
  color: #000 !important;
  font-size: 20px !important;
}/*# sourceMappingURL=adminMain.css.map */