@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  position: relative;
  min-height: 100vh;
  background-color: #F9F9FF;
}

footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  box-shadow: 0px -6px 9px rgba(0, 0, 0, 0.03);
  border-radius: 25px 25px 0px 0px;
  z-index: 999;
}
footer a {
  float: left;
  opacity: 0.4;
}
footer a.active {
  opacity: 1;
}
footer a img {
  width: 19px;
}
footer a img.oferIcn {
  width: 23px;
}
footer span {
  float: left;
  opacity: 0.4;
}
footer span img {
  width: 19px;
}

.LoginPage {
  width: 100%;
  float: left;
  height: 100vh;
  background-image: url(../images/bacGraphic.svg), url(../images/bacGraphicBottom.svg);
  background-size: 100%, 230px;
  background-repeat: no-repeat;
  background-position: top left, bottom right;
  font-family: "Poppins";
  padding: 0 35px;
}
.LoginPage.Login {
  padding: 30px 35px 0;
}
.LoginPage .logMain {
  width: 100%;
  float: left;
  margin: 65px 0 0 0;
}
.LoginPage .logMain.register {
  margin: 30px 0 0 0;
}
.LoginPage .logMain h1 {
  width: 100%;
  float: left;
  margin: 0 0 0 0;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 52px;
}
.LoginPage .logMain .formMain {
  width: 100%;
  float: left;
  margin: 30px 0 0 0;
}
.LoginPage .logMain .formMain .formFild {
  width: 100%;
  float: left;
  border: 1px solid #BAD6FF;
  outline: none;
  font-family: "Poppins";
  background: #BAD6FF;
  border-radius: 7px;
  height: 60px;
  font-size: 16px;
  color: #00327D;
  margin: 0 0 17px 0;
  padding: 0 30px;
}
.LoginPage .logMain .formMain .formFild::-moz-placeholder {
  color: #00327D;
}
.LoginPage .logMain .formMain .formFild::placeholder {
  color: #00327D;
}
.LoginPage .logMain .formMain .formFild.err {
  border-color: #e74a4a;
  color: #f00;
}
.LoginPage .logMain .formMain .formFild.err::-moz-placeholder {
  color: #e74a4a;
}
.LoginPage .logMain .formMain .formFild.err::placeholder {
  color: #e74a4a;
}
.LoginPage .logMain .formMain .formFild.success {
  border-color: #3b974f;
}
.LoginPage .logMain .formMain button {
  float: left;
  border: 0;
  font-size: 16px;
  background-color: #004FC6;
  color: #fff;
  padding: 15px 25px;
  margin: 10px 0 0 0;
  text-transform: capitalize;
  border-radius: 7px;
  outline: none !important;
}
.LoginPage .logMain p {
  width: 100%;
  float: left;
  margin: 25px 0 0 0;
  color: #fff;
}
.LoginPage .logMain p a {
  margin: 0 0 0 12px;
  text-decoration: none;
  color: #fff;
}
.LoginPage .backBttn {
  position: absolute;
  bottom: 58px;
  left: 42px;
  text-decoration: none;
  font-size: 15px;
  color: #004FC6;
}

.afterSuccessLogin {
  font-family: "Poppins";
  position: fixed;
  z-index: 9999;
  bottom: -50px;
  opacity: 0;
  width: 200px;
  left: calc(50% - 100px);
  text-align: center;
  background-color: #e74a4a;
  font-size: 13px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 10px 0;
  transition: all 500ms cubic-bezier(0.4, 0.5, 0.3, 1.3) 200ms;
  font-weight: 500;
  color: #fff;
}
.afterSuccessLogin.animate {
  bottom: 150px;
  opacity: 1;
}

section.mainHeader {
  width: 100%;
  float: left;
  margin: 20px 0 0 0;
  padding: 0 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.mainHeader span.logoWrp {
  float: left;
}
section.mainHeader span.sideWigt {
  float: left;
}
section.mainHeader span.sideWigt a {
  float: left;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0px 4.02387px 6.70645px rgba(0, 0, 0, 0.03);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 11px;
}
section.mainHeader span.sideWigt a svg {
  font-size: 15px;
  color: #c6c6dc;
}
section.mainHeader span.sideWigt button {
  float: left;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0px 4.02387px 6.70645px rgba(0, 0, 0, 0.03);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 11px;
  border: 0;
  outline: none;
}
section.mainHeader span.sideWigt button b {
  position: absolute;
  font-size: 10px;
  top: -8px;
  right: -7px;
  width: 20px;
  height: 20px;
  background: #fbe778;
  border-radius: 100%;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  justify-content: center;
}
section.mainHeader span.sideWigt button svg {
  font-size: 15px;
  color: #c6c6dc;
}

.searchBar {
  width: 100%;
  float: left;
  position: relative;
  margin: 20px 0 0 0;
  padding: 0 13px;
}
.searchBar .bar {
  height: 45px;
  width: 100%;
  float: left;
  background: #FFFFFF;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  font-family: "Poppins";
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 0 23px;
  color: #4d5361;
  background-image: url(../images/searchIcon.svg);
  background-repeat: no-repeat;
  background-position: 96% 50%;
  background-size: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.storyListWrap {
  width: 100%;
  float: left;
  display: flex;
  flex-flow: row;
  gap: 15px;
  padding: 0 13px;
  white-space: nowrap;
  overflow: auto;
  margin: 15px 0 0 0;
}
.storyListWrap::-webkit-scrollbar {
  display: none;
}
.storyListWrap a {
  float: left;
  width: 55px;
  min-width: 55px;
  height: 55px;
  background-color: #fff;
  border-radius: 100%;
  border: 2px solid #FF7B54;
  overflow: hidden;
  padding: 3px;
}
.storyListWrap a img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 100%;
}

.appHomePageWrap {
  width: 100%;
  float: left;
  font-family: "Poppins";
  padding: 0 0;
  margin: 10px 0 0 0;
}
.appHomePageWrap a {
  width: 100%;
  float: left;
}
.appHomePageWrap a picture {
  width: 100%;
  float: left;
}
.appHomePageWrap a picture img {
  width: 100%;
}

.storyDetailsWrap {
  width: 100%;
  float: left;
  height: 100vh;
  position: relative;
}
.storyDetailsWrap video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.storyDetailsWrap video::-webkit-media-controls-volume-slider {
  display: none;
}
.storyDetailsWrap video::-webkit-media-controls-mute-button {
  display: none;
}
.storyDetailsWrap video::-webkit-media-controls-fullscreen-button {
  display: none;
}
.storyDetailsWrap video::-webkit-media-controls-play-button {
  display: none;
}
.storyDetailsWrap video::-webkit-media-controls-current-time-display {
  display: none;
}
.storyDetailsWrap .backBttn {
  position: fixed;
  color: #fff;
  font-size: 25px;
  top: 23px;
  left: 22px;
  z-index: 99999;
}
.storyDetailsWrap article {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.608));
  font-family: "Poppins";
  font-family: "Poppins";
  padding: 131px 17px 40px 17px;
}
.storyDetailsWrap article h2 {
  width: 100%;
  float: left;
  margin: 0 0 0 0;
  color: #fff;
}
.storyDetailsWrap article a {
  float: left;
  background-color: #006eff;
  text-decoration: none;
  font-size: 15px;
  color: #fff;
  padding: 10px 20px;
  margin: 17px 0 0 0;
  border-radius: 5px;
}

.catListWrap {
  width: 100%;
  float: left;
  padding: 0 13px;
  overflow: auto;
  white-space: nowrap;
  display: flex;
  flex-flow: row;
  gap: 6px;
  margin: 6px 0 0 0;
}
.catListWrap::-webkit-scrollbar {
  display: none;
}
.catListWrap a {
  font-family: "Poppins";
  float: left;
  background-color: #fbe778;
  color: #000;
  text-decoration: none;
  padding: 7px 20px;
  font-size: 15px;
  text-transform: capitalize;
  border-radius: 20px;
}

.productListWrap {
  width: 100%;
  float: left;
  padding: 0 13px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 0 0 85px 0;
}
.productListWrap .productBox {
  width: 47.5%;
  height: 195px;
  background: #FFFFFF;
  box-shadow: 3px 5px 17px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  margin: 15px 0 0 0;
  padding: 0 15px;
  position: relative;
}
.productListWrap .productBox .discountInfo {
  top: 8px;
  left: 9px;
  font-family: "Poppins";
  position: absolute;
  font-size: 9px;
  font-weight: 600;
  background: #FFEAE3;
  padding: 4px 8px;
  border-radius: 15px;
  color: #ff6538;
}
.productListWrap .productBox .discountInfo.hide {
  display: none;
}
.productListWrap .productBox picture {
  width: 100%;
  float: left;
  height: 90px;
  margin: 23px 0 0 0;
}
.productListWrap .productBox picture img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.productListWrap .productBox h3 {
  width: calc(100% - 35px);
  float: left;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 700;
  margin: 15px 0 0 0;
}
.productListWrap .productBox h3 small {
  width: 100%;
  float: left;
  color: #1C87EA;
  font-size: 10px;
  font-weight: 500;
}
.productListWrap .productBox h3 b {
  font-weight: 600;
  float: left;
  margin: 0 0px 0 0px;
  font-size: 12px;
}
.productListWrap .productBox h3 b.hide {
  display: none;
}
.productListWrap .productBox h3 b.stripped {
  position: relative;
  font-size: 10px;
  margin: 2px 7px 0 0;
  color: #858585;
}
.productListWrap .productBox h3 b.stripped::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #858585;
  left: 0;
  top: 7px;
}
.productListWrap .productBox button.addWishLIst {
  position: absolute;
  right: 12px;
  top: 15px;
  background-color: #fff;
  border: 0;
  outline: none;
  font-size: 18px;
  z-index: 999;
  color: #d7d7ec;
}
.productListWrap .productBox button.addWishLIst.added {
  color: #e74a4a;
}

.productListHead {
  width: 100%;
  float: left;
  padding: 0px 13px 0px 13px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 23px 0;
}
.productListHead a.backBttn {
  color: #556874;
  font-size: 21px;
}
.productListHead .rightWigt {
  float: left;
  display: flex;
  justify-content: space-between;
  gap: 7px;
  align-items: center;
}
.productListHead .rightWigt button {
  width: 37px;
  height: 37px;
  float: left;
  border-radius: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4.02387px 6.70645px rgba(0, 0, 0, 0.02);
  border: 0;
  color: #556874;
  font-size: 16px;
  position: relative;
}
.productListHead .rightWigt button b {
  position: absolute;
  font-size: 10px;
  top: -8px;
  right: -7px;
  width: 20px;
  height: 20px;
  background: #fbe778;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
}
.productListHead .wishListTxt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-family: "Poppins";
  font-size: 18px;
}

h4.productListHead {
  width: 100%;
  float: left;
  margin: 0 0 5px 0;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.errAlert {
  position: fixed;
  bottom: 120px;
  width: 150px;
  background-color: #e74a4a;
  font-family: "Poppins";
  left: calc(50% - 75px);
  text-align: center;
  color: #fff;
  font-size: 13px;
  padding: 8px 0;
  z-index: 99999;
  border-radius: 22px;
  box-shadow: 0 6px 16px rgba(231, 74, 74, 0.4);
  text-transform: capitalize;
}
.errAlert.wishRemove {
  background-color: #4ea957;
  box-shadow: 0 6px 16px rgba(39, 129, 56, 0.4);
}
.errAlert.fromCenter {
  bottom: 50%;
}
.errAlert.large {
  width: 200px;
  left: calc(50% - 100px);
}

.noProductFind {
  width: 100%;
  float: left;
  font-family: "Poppins";
  margin: 230px 0 0 0;
  text-align: center;
  font-size: 19px;
  color: #d2d2e2;
}

.productDetailsbttn {
  width: 35px;
  float: right;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF7B54;
  box-shadow: 0px 4px 9px rgba(213, 90, 54, 0.29);
  border-radius: 100%;
  color: #fff;
  margin: 10px 0 0 0;
}

.CompleteProductDetailsWrap {
  width: 100%;
  float: left;
  min-height: 100%;
}
.CompleteProductDetailsWrap picture {
  width: 100%;
  float: left;
  margin: 70px 0 20px 0;
  padding: 0 15px;
}
.CompleteProductDetailsWrap picture img {
  width: 100%;
  height: 280px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.CompleteProductDetailsWrap article {
  width: 100%;
  float: left;
  min-height: calc(100vh - 370px);
  background: #6666A5;
  border-radius: 36px 36px 0px 0px;
  padding: 35px 42px 70px;
  position: relative;
}
.CompleteProductDetailsWrap article h1 {
  width: 100%;
  float: left;
  margin: 0 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #D1D1F3;
}
.CompleteProductDetailsWrap article h1 small {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #D1D1F3;
  margin: 12px 0 0 0;
}
.CompleteProductDetailsWrap article h1 small b {
  font-weight: 500;
  color: #fff;
  margin: 0 12px 0 0px;
}
.CompleteProductDetailsWrap article h1 small b.stripped {
  font-size: 14px;
  color: #d1d1f3;
  margin: 0px 12px 0 0;
  position: relative;
}
.CompleteProductDetailsWrap article h1 small b.stripped::after {
  width: 100%;
  position: absolute;
  height: 1.3px;
  background-color: #d1d1f3;
  top: 7px;
  left: 0;
  transform-origin: top left;
  content: "";
  transform: rotate(7deg);
}
.CompleteProductDetailsWrap article h1 small b.hide {
  display: none;
}
.CompleteProductDetailsWrap article p {
  width: 100%;
  float: left;
  margin: 20px 0 0 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}
.CompleteProductDetailsWrap article .addBttnsWrap {
  width: 100%;
  position: absolute;
  bottom: 25px;
  left: 0;
  padding: 0 42px;
  display: flex;
  justify-content: space-between;
}
.CompleteProductDetailsWrap article .addBttnsWrap .bttn {
  font-family: "Poppins";
  background: #FFA800;
  border-radius: 8px;
  border: 0;
  outline: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  width: 47.5%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.CompleteProductDetailsWrap article .addBttnsWrap a {
  color: #41417C !important;
  background-color: #fff !important;
  text-decoration: none;
}
.CompleteProductDetailsWrap article .addWish {
  width: 56px;
  height: 56px;
  position: absolute;
  background: #F9F9FF;
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.03), 0px 4px 7px rgba(0, 0, 0, 0.1);
  border: 0;
  outline: none !important;
  border-radius: 100%;
  right: 32px;
  top: -28px;
  font-size: 24px;
  color: #d7d7f7;
}
.CompleteProductDetailsWrap article .addWish.added {
  color: #e74a4a;
}

.detailsHead {
  position: absolute;
  top: 5px;
  left: 0;
}

.filterPopupWrap {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.03);
}
.filterPopupWrap .closeFilterPop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.filterPopupWrap .filtermainContain {
  width: 100%;
  min-height: 330px;
  bottom: -100%;
  border-radius: 30px 30px 0 0;
  background-color: #fff;
  position: absolute;
  box-shadow: 0 -16px 25px rgba(0, 0, 0, 0.02);
  transition: 0.3s ease-in-out;
  z-index: 99;
  padding: 30px 15px;
}
.filterPopupWrap .filtermainContain.open {
  bottom: 0;
}

.priceRangeWrap {
  width: 100%;
  float: left;
  margin: 0 0 10px 0;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #ddd;
}
.priceRangeWrap h4 {
  width: 100%;
  float: left;
  margin: 7px 0;
  font-family: "Poppins";
  font-weight: 500;
}
.priceRangeWrap .horizontal-slider {
  width: 100%;
  max-width: 500px;
  margin: auto;
  float: left;
  height: 50px;
}
.priceRangeWrap .horizontal-slider .example-thumb {
  top: 9px;
  width: 25px;
  outline: none;
  height: 25px;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #3774ff;
  border-radius: 100%;
  display: block;
  line-height: 76px;
  font-size: 11px;
  font-weight: 500;
  font-family: "Poppins";
  text-align: center;
}
.priceRangeWrap .horizontal-slider .example-thumb.active {
  color: #3b974f;
}
.priceRangeWrap .horizontal-slider .example-track {
  position: relative;
  background: #ddd;
}
.priceRangeWrap .horizontal-slider .example-track.example-track-1 {
  background: #83a9ff;
}
.priceRangeWrap .horizontal-slider .example-track-2 {
  background: #ddd;
}
.priceRangeWrap .horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}

.filterBttn {
  float: left;
  font-family: "Poppins";
  border: 0;
  background: #1c87ea;
  color: #fff;
  margin: 10px 0 0 0;
  padding: 12px 19px;
  font-weight: 500;
  border-radius: 5px;
  outline: none !important;
}

.selectSubcategories {
  width: 100%;
  float: left;
}
.selectSubcategories h4 {
  width: 100%;
  float: left;
  margin: 7px 0;
  font-family: "Poppins";
  font-weight: 500;
}
.selectSubcategories .subCatList {
  width: 100%;
  float: left;
  display: flex;
  flex-flow: row wrap;
  gap: 0px 10px;
  padding: 10px 0;
}
.selectSubcategories .subCatList .subCatBox {
  position: relative;
  font-family: "Poppins";
  font-size: 13px;
  padding: 12px 17px;
  background: #e9e9f4;
  font-weight: 500;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  margin: 0 0 7px 0;
}
.selectSubcategories .subCatList .subCatBox p {
  margin: 0;
  position: relative;
  z-index: 10;
}
.selectSubcategories .subCatList .subCatBox b {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.selectSubcategories .subCatList .subCatBox input {
  display: none;
}
.selectSubcategories .subCatList .subCatBox input:checked ~ b {
  background-color: #49af54;
}
.selectSubcategories .subCatList .subCatBox input:checked ~ p {
  color: #fff;
}

.quantity-picker {
  position: relative !important;
  border: 0 !important;
  margin: 20px 0 28px 0;
  background: #fff;
  border-radius: 21px !important;
}
.quantity-picker .quantity-display {
  padding: 4px 0 !important;
  height: auto !important;
  font-size: 16px;
  font-family: "POPPINS";
  width: 33px !important;
}
.quantity-picker .quantity-modifier {
  height: 40px;
  width: 40px;
  font-size: 17px;
  background: #f3f3f3;
  color: #888;
  border: 0 solid #dbdbdb;
  text-align: center;
  cursor: pointer;
  border-radius: 100%;
}

.wishListTotalWrap {
  width: 100%;
  float: left;
  margin: 0px 0 90px 0;
  padding: 0 13px;
  display: flex;
  flex-flow: column-reverse;
}
.wishListTotalWrap .listBox {
  width: 100%;
  float: left;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 8px 0;
  box-shadow: 3px 5px 17px rgba(0, 0, 0, 0.03);
  padding: 20px 15px;
  border-radius: 15px;
  background: #f9f9ff;
  position: relative;
}
.wishListTotalWrap .listBox.osList {
  border: 1px solid #ddd;
  box-shadow: none !important;
  border-radius: 10px;
}
.wishListTotalWrap .listBox.osList a.more {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 14px;
  font-family: "Poppins";
  display: none;
  color: #004fc6;
  text-decoration: none;
  font-weight: 500;
  margin: -10px 0 17px 0;
}
.wishListTotalWrap .listBox.osList a.more.show {
  display: block;
}
.wishListTotalWrap .listBox picture {
  width: 70px;
  height: 70px;
  float: left;
}
.wishListTotalWrap .listBox picture img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.wishListTotalWrap .listBox h4 {
  width: calc(100% - 171px);
  font-family: "Poppins";
  float: left;
  margin: 0 0 0 23px;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  color: #61617d;
}
.wishListTotalWrap .listBox h4 small {
  display: block;
  margin: 6px 0 0 0;
  color: #64a241;
}
.wishListTotalWrap .listBox h4 small b {
  font-weight: 500;
}
.wishListTotalWrap .listBox h4 small b.stripped {
  margin: 0 7px 0 0;
  color: #828282;
  position: relative;
}
.wishListTotalWrap .listBox h4 small b.stripped strong {
  font-weight: 500;
}
.wishListTotalWrap .listBox h4 small b.stripped::after {
  width: 100%;
  position: absolute;
  height: 1.3px;
  background-color: #828282;
  top: 5px;
  left: 0;
  transform-origin: top left;
  content: "";
  transform: rotate(7deg);
}
.wishListTotalWrap .listBox h4 small b.hide {
  display: none;
}
.wishListTotalWrap .listBox .goToProduct {
  position: absolute;
  right: 23px;
  top: 50%;
  color: #86c264;
  font-size: 17px;
}
.wishListTotalWrap .listBox .quantity-picker {
  z-index: 1;
}
.wishListTotalWrap .listBox .quantity-picker .quantity-display {
  transform: rotate(90deg);
}
.wishListTotalWrap .listBox .quantity-picker .modifier-left {
  transform: rotate(90deg);
}
.wishListTotalWrap .listBox .DelectSlot {
  position: absolute;
  right: 65px;
  border: 0;
  font-size: 19px;
  background: transparent;
  color: #c2c2dd;
}

.swipe-to-delete .js-delete {
  background-color: #ff8c8c !important;
  height: 71px;
  margin: 29px 0 0 2.5%;
  width: 95%;
  border-radius: 10px;
}

.noItems {
  width: 100%;
  position: absolute;
  top: 110px;
  left: 0;
  text-align: center;
  margin: 0;
  font-family: "Poppins";
  color: #9999b6;
  font-weight: 500;
}

.listBox .quantity-picker {
  position: absolute !important;
  margin: 0 0;
  transform: scale(0.8) rotate(-90deg);
  right: -25px;
}

.cartTotalDes {
  width: 90%;
  padding: 0 0px;
  position: fixed;
  bottom: 20px;
  left: 5%;
  background-color: #FFA800;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-radius: 35px;
  z-index: 99;
  height: 65px;
}
.cartTotalDes h4 {
  float: left;
  margin: 0 0 0 30px;
  font-family: "Poppins";
  color: #000;
  font-weight: 500;
  font-size: 14px;
}
.cartTotalDes a, .cartTotalDes button {
  outline: none !important;
  font-family: "Poppins";
  border: 0;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  background: #ffc659;
  font-weight: 500;
  color: #000;
  display: none;
  text-decoration: none;
  height: 65px;
  font-weight: 600;
}
.cartTotalDes a svg, .cartTotalDes button svg {
  margin: 0 0 0 8px;
}
.cartTotalDes a.show, .cartTotalDes button.show {
  display: block;
}

.bodyHome {
  width: 100%;
  float: left;
  margin: 0 0 75px 0;
}

.TrendingSlot {
  width: 100%;
  float: left;
  margin: 20px 0 0 0;
}
.TrendingSlot h4 {
  width: 100%;
  float: left;
  margin: 0 0 -8px 0;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0 13px;
}
.TrendingSlot .tpScroll {
  width: 100%;
  float: left;
  padding: 15px 13px;
  margin: 10px 0 0 0;
  display: flex;
  gap: 13px;
  white-space: nowrap;
  overflow: auto;
}
.TrendingSlot .tpScroll::-webkit-scrollbar {
  display: none;
}
.TrendingSlot .tpScroll .tpBox {
  min-width: 165px;
  max-width: 165px;
  height: 194px;
  background-color: #fff;
  float: left;
  box-shadow: 2.87027px 4.78378px 16.2649px rgba(0, 0, 0, 0.03);
  border-radius: 9.56757px;
  white-space: normal;
  padding: 0 13px;
}
.TrendingSlot .tpScroll .tpBox picture {
  width: 100%;
  float: left;
  height: 90px;
  margin: 23px 0 0 0;
}
.TrendingSlot .tpScroll .tpBox picture img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.TrendingSlot .tpScroll .tpBox h3 {
  width: calc(100% - 35px);
  float: left;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 700;
  margin: 12px 0 0 0;
}
.TrendingSlot .tpScroll .tpBox h3 small {
  width: 100%;
  float: left;
  color: #1C87EA;
  font-size: 10px;
  font-weight: 500;
}
.TrendingSlot .tpScroll .tpBox button.addWishLIst {
  position: absolute;
  right: 12px;
  top: 15px;
  background-color: #fff;
  border: 0;
  outline: none;
  font-size: 18px;
  z-index: 999;
  color: #d7d7ec;
}
.TrendingSlot .tpScroll .tpBox button.addWishLIst.added {
  color: #e74a4a;
}

.bannerAddsection {
  width: 100%;
  float: left;
  margin: 8px 0 0 0;
  display: flex;
  padding: 0 13px;
  gap: 13px;
  flex-flow: row;
  white-space: nowrap;
  overflow: auto;
}
.bannerAddsection::-webkit-scrollbar {
  display: none;
}
.bannerAddsection a {
  min-width: 310px;
  float: left;
}
.bannerAddsection a img {
  width: 100%;
}

.categoriesPop {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #F9F9FF;
  overflow: auto;
  padding: 0 0 80px 0;
}
.categoriesPop::-webkit-scrollbar {
  display: none;
}
.categoriesPop .cathead {
  width: 100%;
  background-color: #fff;
  position: fixed;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  border-bottom: 1px solid #ddd;
}
.categoriesPop .cathead .backBttn {
  float: left;
  font-family: "Poppins";
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 13px;
}
.categoriesPop .cathead .backBttn svg {
  font-size: 18px;
  color: #000;
  margin: 0 11px 0 0;
}
.categoriesPop .cathead .rightWigt {
  float: left;
  display: flex;
  align-items: center;
  gap: 20px;
}
.categoriesPop .cathead .rightWigt a {
  float: left;
  color: #000;
  text-decoration: none;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px;
}
.categoriesPop .categoryListing {
  width: 100%;
  float: left;
  margin: 65px 0 8px 0;
  padding: 0 15px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.categoriesPop .categoryListing .catBox {
  width: 30%;
  float: left;
  height: 90px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-size: 13px;
  text-decoration: none;
  background-color: #ebebff;
  margin: 15px 0 0 0;
  color: #5f5f8f;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 8px;
}

.searchWrapSingle {
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #fff;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 3px 5px 17px rgba(0, 0, 0, 0.02);
}
.searchWrapSingle .closeSearch {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchWrapSingle .closeSearch svg {
  font-size: 21px;
}
.searchWrapSingle .searchBox {
  width: calc(100% - 90px);
  float: left;
  border: 0;
  background-color: #fff;
  outline: none;
  font-family: "Poppins";
  font-size: 16px;
}

.loadingScreen {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadingScreen .loader {
  position: absolute;
}
.loadingScreen .loader svg {
  width: 70px !important;
  height: 70px !important;
}

.profilePageWrap {
  width: 100%;
  float: left;
  height: 100vh;
}
.profilePageWrap .profilePageHead {
  width: 100%;
  float: left;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.profilePageWrap .profilePageHead picture {
  width: 109px;
  height: 109px;
  border-radius: 100%;
  position: relative;
  z-index: 9;
  background-color: #77BEFF;
  border: 3px solid #fff;
  margin: 90px 0 0 0;
  background-image: url(../images/defaultProfilePic.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.profilePageWrap .profilePageHead picture img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  border-radius: 100%;
}
.profilePageWrap .profilePageHead picture button {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0;
  bottom: 0;
  background: #C2E0B1;
  border: 0;
  outline: none !important;
  color: #4C882A;
  border-radius: 100%;
  z-index: 9;
}
.profilePageWrap .profilePageHead h4 {
  width: 100%;
  float: left;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #474773;
  margin: 10px 0 0 0;
  position: relative;
  z-index: 10;
}
.profilePageWrap .profilePageHead h4 small {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 4px 0 0 0;
  color: #9C9CC6;
}
.profilePageWrap .profilePageHead::after {
  width: 100%;
  height: 147px;
  left: 0px;
  top: 0px;
  background: linear-gradient(101.26deg, #7176FF 5.07%, #1010BA 94.92%);
  position: absolute;
  content: "";
}

.profileHead {
  position: absolute;
  z-index: 99;
}
.profileHead a.backBttn {
  color: #fff;
}
.profileHead .wishListTxt {
  color: #fff;
}
.profileHead .rightWigt button {
  background: rgba(255, 255, 255, 0.1803921569);
  color: #ffffff;
}
.profileHead .wishListTxt {
  font-size: 20px;
  font-weight: 500;
}

.profilePageContents {
  width: 100%;
  float: left;
  margin: 28px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.profilePageContents .ppBlock {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background: #F9F9FF;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  margin: 10px 0 0 0;
  padding: 0 25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #474773;
  text-decoration: none;
  border: 0;
  outline: none !important;
}
.profilePageContents .ppBlock svg {
  color: #AAADFF;
}

.getLocationPopWrp {
  width: 100%;
  min-height: 100%;
  position: relative;
  float: left;
  margin: 0 0 90px 0;
}
.getLocationPopWrp .deliveryWrap {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.05);
  top: 0;
  left: 0;
  z-index: 9999;
}
.getLocationPopWrp .deliveryWrap .closeDPop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.getLocationPopWrp .locationWrapMain {
  width: calc(100% - 40px);
  min-height: 180px;
  background-color: #fff;
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 20px;
  box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.08);
  padding: 30px;
  margin: 8px 0;
  z-index: 99;
}
.getLocationPopWrp .locationWrapMain h4 {
  width: 100%;
  float: left;
  margin: 0;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
}
.getLocationPopWrp .locationWrapMain h4 button {
  float: right;
  background: #fff;
  border: 0;
  padding: 0;
  outline: none !important;
  font-size: 17px;
  color: #86c264;
}
.getLocationPopWrp .locationWrapMain input {
  width: 100%;
  float: left;
  color: #000;
  border: 0;
  font-family: "Poppins";
  margin: 13px 0 0 0;
  background: #f4f4f4;
  padding: 16px 20px;
  border-radius: 8px;
  overflow: hidden;
  outline: none !important;
}
.getLocationPopWrp .locationWrapMain .enterLBttn {
  float: left;
  border: 0;
  font-family: "Poppins";
  background-color: #cfe8ff;
  padding: 12px 25px;
  margin: 15px 0 0 0;
  border-radius: 8px;
  color: #006fd4;
  font-weight: 600;
}

.chooseimagePopWrap {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
}
.chooseimagePopWrap .choosaeImagecontain {
  position: absolute;
  bottom: -125px;
  left: 0;
  width: 100%;
  min-height: 120px;
  background-color: #fff;
  border-radius: 30px 30px 0 0;
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px;
  z-index: 90;
  transition: 0.3s ease-in-out;
}
.chooseimagePopWrap .choosaeImagecontain.open {
  bottom: 0;
}
.chooseimagePopWrap .choosaeImagecontain label {
  float: left;
}
.chooseimagePopWrap .choosaeImagecontain label input {
  display: none;
}
.chooseimagePopWrap .closeimgPop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pPagebttns {
  width: 90%;
  float: left;
  display: flex;
  justify-content: space-between;
  margin: 25px 0 90px 0;
}
.pPagebttns button {
  width: 46%;
  float: left;
  border: 0;
  outline: none;
  background: #1c87ea;
  font-family: "POPPINS";
  font-size: 14px;
  font-weight: 500;
  padding: 11px 0;
  border-radius: 25px;
  color: #fff;
}
.pPagebttns button.logOut {
  background-color: #ffdddd;
  color: #d84747;
}

.editProfileWrap {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.02);
  z-index: 9999;
  position: fixed;
}
.editProfileWrap .editProfileFrm {
  width: 100%;
  min-height: 300px;
  background-color: #fff;
  border-radius: 30px 30px 0 0;
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.05);
  left: 0;
  position: absolute;
  bottom: -100%;
  padding: 35px;
  z-index: 99;
  transition: 0.4s ease-in-out;
}
.editProfileWrap .editProfileFrm.open {
  bottom: 0;
}
.editProfileWrap .editProfileFrm h4 {
  width: 100%;
  float: left;
  position: relative;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins";
  font-weight: 500;
}
.editProfileWrap .editProfileFrm .formWrap {
  width: 100%;
  float: left;
  margin: 20px 0 0 0;
}
.editProfileWrap .editProfileFrm .formWrap .formFild {
  width: 100%;
  float: left;
  margin: 4px 0;
  background-color: #f9f9fd;
  border: 0;
  outline: none;
  font-family: "Poppins";
  padding: 15px 24px;
}
.editProfileWrap .editProfileFrm .formWrap .submitBttn {
  width: 100%;
  float: left;
  background-color: #006fd4;
  color: #fff;
  font-family: "Poppins";
  border: 0;
  outline: none;
  padding: 13px 0;
  margin: 8px 0 0 0;
}
.editProfileWrap .closeProfileOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.showHidePass {
  position: absolute;
  margin: 99px 0 0 -40px;
  color: #004fc6;
}
.showHidePass.onReg {
  margin: 252px 0 0 -40px;
}

.deliverDetails {
  width: 100%;
  float: left;
  margin: 15px 0 20px 0;
  padding: 0 13px 22px;
  border-bottom: 1px solid #ddd;
}
.deliverDetails h4 {
  width: 100%;
  float: left;
  margin: 0;
  font-size: 15px;
  color: #000;
  font-family: "Poppins";
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deliverDetails h4 button {
  border: 0;
  outline: none;
  background-color: #cfecbf;
  font-family: "Poppins";
  color: #4c7a27;
  font-weight: 500;
  font-size: 12px;
  padding: 6px 13px;
  border-radius: 17px;
}
.deliverDetails span {
  width: 100%;
  float: left;
  margin: 12px 0 0px 0;
}
.deliverDetails h3 {
  width: 100%;
  float: left;
  margin: 0px 0 0px 0;
  font-size: 14px;
  color: #000;
  font-family: "Poppins";
  font-weight: 600;
  text-transform: capitalize;
}
.deliverDetails h3 small {
  width: 100%;
  float: left;
  margin: 5px 0 -8px 0;
}
.deliverDetails p {
  width: 100%;
  float: left;
  font-family: "Poppins";
  margin: 2px 0;
  font-size: 14px;
  position: relative;
  padding: 0 0px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deliverDetails p a {
  background: #c1e1ff;
  color: #004fc6;
  border-radius: 100%;
  min-width: 29px;
  max-width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout {
  margin-bottom: 0;
}

.priceDetails {
  width: 100%;
  float: left;
  padding: 25px 20px;
  margin: 15px 0 100px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.priceDetails h3 {
  width: 100%;
  float: left;
  margin: 0 0 18px 0;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
}
.priceDetails .detailsBlock {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins";
  font-size: 12px;
  margin: 0 0 8px 0;
}
.priceDetails .detailsBlock b {
  font-weight: 500;
  float: left;
}

.paymentTypeWrap {
  width: 100%;
  float: left;
  margin: 30px 0 0 0;
  background-color: #fff;
}
.paymentTypeWrap label {
  width: 100%;
  float: left;
  padding: 12px 15px;
  border-bottom: 1px solid #eee;
  font-family: "Poppins";
}
.paymentTypeWrap label:last-child {
  border: 0;
}

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 9999;
}
.wrapper .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.wrapper .checkmark {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0 auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.wrapper .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 60px #7ac142;
  }
}
.wrapper h4 {
  width: 100%;
  float: left;
  margin: 25px 0 0 0;
  font-family: "Poppins";
  text-align: center;
  font-size: 32px;
  font-weight: 400;
}
.wrapper .backBttn {
  display: inline-block;
  font-family: "Poppins";
  background: #fbe778;
  color: #000;
  font-size: 15px;
  text-decoration: none;
  padding: 12px 29px;
  margin: 16px 0 0 0;
  border-radius: 6px;
  font-weight: 500;
}

.locationListing {
  width: 100%;
  float: left;
  margin: 20px 0;
  padding: 0 15px;
  display: flex;
  flex-flow: column-reverse;
}
.locationListing .locationBox {
  width: 100%;
  float: left;
  margin: 7px 0;
  font-family: "Poppins";
  border-top: 1px solid #ddd;
  padding: 11px 90px 11px 0;
  position: relative;
}
.locationListing .locationBox p {
  width: 100%;
  float: left;
  margin: 4px 0;
  font-size: 13px;
}
.locationListing .locationBox:last-child {
  border: 0;
}
.locationListing .locationBox a {
  position: absolute;
  right: 0;
  top: 19px;
  color: #1c87ea;
}
.locationListing .locationBox button {
  position: absolute;
  right: 44px;
  border: 0;
  background: transparent;
  top: 24px;
  color: #e74a4a;
}

.deliveryPopTrg {
  width: calc(100% - 100px);
  background-color: #86c264;
  color: #fff;
  font-family: "Poppins";
  text-align: center;
  margin: 0 0 0 50px;
  float: left;
  padding: 11px 0;
  border-radius: 8px;
  font-weight: 500;
}
.deliveryPopTrg.anchor {
  text-decoration: none;
  margin: 12px 0 0 0;
  width: 100%;
}

.editDAddress {
  position: relative;
}
.editDAddress .deliveryWrap {
  position: relative;
  z-index: 0;
}

.changeAddressPopWrap {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.03);
  top: 0;
  left: 0;
}
.changeAddressPopWrap .closepop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.changeAddressPopWrap .changeAddressInn {
  width: 100%;
  position: absolute;
  min-height: 200px;
  background-color: #fff;
  bottom: -100%;
  left: 0;
  border-radius: 25px 25px 0 0;
  padding: 30px 25px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);
  transition: 0.4s ease-in-out;
  z-index: 99;
}
.changeAddressPopWrap .changeAddressInn.open {
  bottom: 0;
}
.changeAddressPopWrap .changeAddressInn .changeAddContent {
  width: 100%;
  float: left;
}
.changeAddressPopWrap .changeAddressInn .changeAddContent label {
  width: 100%;
  float: left;
  display: flex;
  align-items: flex-start;
  font-family: "Poppins";
  font-size: 13px;
  padding: 8px 0;
  font-weight: 500;
}
.changeAddressPopWrap .changeAddressInn .changeAddContent label input {
  margin: 5px 8px 0 0px;
  float: left;
}

.paymentPage {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 99;
  background-color: #fff;
}

.addLocationAnimation {
  position: fixed;
}
.addLocationAnimation .locationWrapMain {
  top: -50%;
  width: 100%;
  left: 0;
  margin: 0;
  border-radius: 0 0 30px 30px;
  transition: 0.5s ease-in-out;
  opacity: 0;
}
.addLocationAnimation .locationWrapMain.animate {
  top: 0;
  opacity: 1;
}

.allGrupedProducts {
  width: 100%;
  float: left;
}
.allGrupedProducts .productBoxMain {
  width: 100%;
  float: left;
  padding: 30px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.allGrupedProducts .productBoxMain:last-child {
  border: 0;
}
.allGrupedProducts .productBoxMain img {
  width: 75px;
  height: 75px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.allGrupedProducts .productBoxMain article {
  width: calc(100% - 100px);
  float: left;
}
.allGrupedProducts .productBoxMain article h4 {
  width: 100% !important;
  margin: 0 !important;
}
.allGrupedProducts .productBoxMain article h3 {
  width: 100%;
  float: left;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 600;
  margin: 11px 0 0 0;
  color: #508532;
}

.orderDetailsInfo {
  width: 100%;
  float: left;
  font-family: "Poppins";
}
.orderDetailsInfo h3 {
  width: 100%;
  float: left;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0 5px 0;
}
.orderDetailsInfo p {
  width: 100%;
  float: left;
  font-size: 11px;
  font-weight: 500;
  margin: 0 0 5px 0;
  color: #000;
}
.orderDetailsInfo p small {
  margin: 0 0 0 15px;
}
.orderDetailsInfo p b {
  display: block;
  font-weight: 500;
}

.totalPrice {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  font-family: "Poppins";
  border-top: 1px solid #ddd;
}
.totalPrice p {
  float: left;
  font-weight: 500;
  color: #000;
  font-size: 12px;
}

.OfferListWrap {
  width: 100%;
  float: left;
  padding: 0 25px;
  display: flex;
  flex-flow: column-reverse;
}
.OfferListWrap .offerBox {
  width: 100%;
  float: left;
  margin: 8px 0;
}
.OfferListWrap .offerBox img {
  width: 100%;
}

.couponCodeBox {
  width: 100%;
  float: left;
  margin: 15px 0 0 0;
  padding: 0 30px;
  text-align: center;
}
.couponCodeBox .inpuCoupon {
  width: calc(100% - 100px);
  float: left;
  font-family: "Poppins";
  height: 50px;
  border: 1px solid #ddd;
  padding: 0 21px;
  border-radius: 25px 0px 0 24px;
  outline: none !important;
}
.couponCodeBox button {
  width: 100px;
  background-color: #006eff;
  height: 50px;
  border: 0;
  font-family: "Poppins";
  color: #fff;
  border-radius: 0 24px 24px 0;
}/*# sourceMappingURL=appmain.css.map */