@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

*{
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
$clear: 0 0 0 0;

/************** colors ************/

$themecolor: #AAADFF;
$themeDark: #454897;
$successColor: #86c264;

@mixin importantWl {
  width: 100%;
  float: left;
}

body {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 5px;
}
  
::-webkit-scrollbar-track {
    background: #374151; 
    border-radius: 6px;
}
  
::-webkit-scrollbar-thumb {
    background: #9CA3AF; 
    border-radius: 6px;
}

.completeLogWrap {
    @include importantWl();
    height: 100vh;
    background-color: #fff;
    position: relative;
    font-family: 'Poppins';
    span.goLink {
        position: absolute;
        right: 34px;
        top: 22px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-align: right;
        color: #9F9F9F;
        z-index: 99;
        a {
            font-weight: 500;
            color: #454897;
            text-decoration: none;
            margin: 0 0 0 9px;
        }
    }
    &::after {
        content: '';
        width: 435px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $themecolor;
        background-image: url(../images/loginRocket.svg);
        background-size: 360px;
        background-repeat: no-repeat;
        background-position: right bottom;
    }
    .logContainWrap {
        width: calc(100% - 435px);
        float: right;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .logmain {
            width: 550px;
            float: left;
            min-height: 100px;
            position: relative;
            h1 {
                @include importantWl();
                margin: $clear;
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                color: #000000;
                small {
                    @include importantWl();
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 22px;
                    color: #9F9F9F;
                    margin: 10px  0 0 0;
                }
            }
            .mainLogForm {
                @include importantWl();
                margin: 35px 0 0 0;
                .userfld {
                    @include importantWl();
                    height: 65px;
                    background-color: #F2F3FF;
                    border-radius: 8px;
                    border: 1px solid #F2F3FF;
                    font-family: 'Poppins';
                    padding: 0 0 0 69px;
                    font-size: 14px;
                    font-weight: 300;
                    background-image: url(../images/userIcon.svg);
                    background-repeat: no-repeat;
                    background-position: 26px 50%;
                    background-size: 25px;
                    margin: 0 0 18px 0;
                    outline: none;
                    &::placeholder {
                        color: #A4A5C1;
                    }
                }
                input[type="password"] {
                    background-image: url(../images/lockicon.svg);
                }
                input[type="email"] {
                    background-image: url(../images/envelope.svg);
                }
                .subBttn {
                    border: 0;
                    outline: none;
                    background-color: $successColor;
                    font-size: 16px;
                    color: #fff;
                    font-family: 'Poppins';
                    font-weight: 500;
                    border-radius: 8px;
                    padding: 13px 38px;
                    cursor: pointer;
                    box-shadow: 0px 5px 10px rgba(0, 0, 0, .14);
                }
            }
            &.errorAlrt {
                animation: shake 0.5s;
                animation-iteration-count: infinite;
            }
            @keyframes shake {
                0% { transform: translate(0px, 0px); }
                20% { transform: translate(-30px, 0px); }
                40% { transform: translate(30px, 0px); }
                60% { transform: translate(-30px, 0px); }
                80% { transform: translate(30px, 0px); }
                100% { transform: translate(0px, 0px); }
            }
        }
        
    }
}
.messageContain {
    width: 550px;
    left: calc(50% - 275px);
    background-color: #fff;
    color: #6d6d6d;
    position: fixed;
    top: 10px;
    font-size: 13px;
    font-weight: 500;
    box-shadow: 0 2px 11px rgb(0 0 0 / 5%);
    padding: 13px 27px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: all 400ms cubic-bezier(0.4, 0.5, 0.3, 2) 150ms;
    font-family: 'Poppins';
    z-index: 9999;
    &.animate {
        top: 60px;
        opacity: 1;
    }
    svg.mainIcon {
        margin: 0 10px 0 0px;
        font-size: 17px;
    }
    &.successMsg {
        background-color: #bceca2;
        color: #608f45;
    }
    .closeMsg {
        position: absolute;
        right: 19px;
        top: 13px;
        cursor: pointer;
        opacity: .5;
    }
}

.adminPanelMainWrap {
    @include importantWl();
    min-height: 100vh;
    position: relative;
    background-color: #ECF0F3;
    aside {
        width: 250px;
        height: calc(100vh - 50px);
        position: fixed;
        left: 25px;
        top: 25px;
        background-color: $themeDark;
        border-radius: 15px;
        padding: 90px 40px;
        font-family: 'Ubuntu';
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        overflow: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        .sidebarLinks {
            @include importantWl();
            position: relative;
            display: flex;
            flex-flow: column;
            span {
                @include importantWl();
                a {
                    @include importantWl();
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    text-decoration: none;
                    color: #fff;
                    text-transform: capitalize;
                    font-weight: 300;
                    opacity: .6;
                    padding: 13px 0;
                    margin: 0;
                    img {
                        width: 16px;
                        margin: 0px 21px 0 0px;
                    }
                    &.active {
                        opacity: 1;
                    }
                }
            }
            
        }
    }
}

.rightSideContents {
    width: 250px;
    right: 50px;
    top: 25px;
    position: fixed;
    .adminProfile {
        @include importantWl();
        height: unset;
        background: #EAEFF2;
        border: 1px solid #DFE8EE;
        box-shadow: 10px 10px 38px rgba(209, 217, 230, 0.71), -9px -14.42px 41.17px #FFFFFF;
        border-radius: 16px;
        min-height: 200px;
        padding: 51px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        font-family: 'Poppins';
        picture {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            border: 3px solid #fff;
            background-color: $themecolor;
            background-image: url(../images/userPlaceholder.svg);
            background-size: 60px;
            background-position: center;
            background-repeat: no-repeat;
        }
        h4 {
            @include importantWl();
            text-align: center;
            margin: 20px 0 0 0;
            font-style: normal;
            font-weight: 600;
            font-size: 21px;
            letter-spacing: 0.01em;
            color: #717C98;
            small {
                @include importantWl();
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                color: #8993A5;
                margin: 3px 0 0 0;
            }
        }
        a {
            float: left;
            width: 129px;
            height: 45px;
            background: #F37171;
            box-shadow: 0px 4px 12px rgba(243, 113, 113, 0.34);
            border-radius: 30px;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            color: #EFD4D4;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none !important;
            margin: 15px 0 0 0;
        }
    }
    .customersListing {
        @include importantWl();
        margin: 30px 0 0 0;
        h4 {
            @include importantWl();
            margin: 0 0 0 0;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 33px;
            color: #717C98;
        }
        .mainListing {
            @include importantWl();
            margin: 5px 0 0 0;
            .customerListBox {
                @include importantWl();
                margin: 10px 0 0 0;
                padding: 15px;
                background: #EAEFF2;
                border: 1px solid #DFE8EE;
                box-shadow: 10px 10px 38px rgba(209, 217, 230, 0.71), -9px -14.42px 41.17px #FFFFFF;
                border-radius: 16px;
                display: flex;
                align-items: center;
                picture {
                    width: 60px;
                    height: 57px;
                    float: left;
                    border-radius: 8px;
                    background-color: $themecolor;
                }
                p {
                    float: left;
                    margin: 0 0 0 15px;
                    width: 122px;
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: unset;
                    color: #556874;
                }
            }
        }
    }
}

.maindbBody {
    width: calc(100% - 564px);
    float: left;
    margin-left: 270px;
    margin-top: 35px;
    padding: 0 35px;
    header.adminHeader {
        @include importantWl();
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #D9E2E8;
        padding: 0 0 20px 0;
        span.logoWrap {
            width: 180px;
            float: left;
            img {
                width: 100%;
            }
        }
        .bellIcon {
            float: left;
            svg {
                font-size: 22px;
                color: #717c98;
            }
        }
    }
}

.completeProductWrapper {
    @include importantWl();
    margin: 25px 0 0 0;
    .addProductWrap {
        @include importantWl();
        margin: 25px 0 0 0;
        .usrInpt {
            width: 100%;
            float: left;
            font-family: "Poppins";
            background: #eaeff2;
            border: 1px solid #d2d6d9;
            padding: 15px 25px;
            outline: none;
            margin: 8px 0;
            border-radius: 9px;
            color: #000;
            font-weight: 400;
            font-size: 14px;
            // box-shadow: 0px 3px 14px rgb(0 0 0 / 5%);
        }
        .textLabel {
            width: 100%;
            float: left;
            font-family: "Poppins";
            color: #f37171;
            font-weight: 500;
            font-size: 14px;
            margin: 10px 0 -5px 0;
            padding: 0 4px;
        }

        .typePhotoInput {
            width: 100%;
            float: left;
            font-family: "Poppins";
            background: #eaeff2;
            border: 1px solid #d2d6d9;
            padding: 15px 25px;
            padding-left: 55px;
            outline: none;
            margin: 8px 0;
            border-radius: 9px;
            color: #000;
            font-weight: 400;
            position: relative;
            background-image: url(../images/iconImg.svg);
            background-repeat: no-repeat;
            background-position: 20px 50%;
            background-size: 21px;
            b {
                font-weight: normal;
                font-size: 14px;
                float: left;
                width: 88%;
                word-break: break-all;
            }
            input[type='file'] {
                display: none;
            }
            picture {
                width: 45px;
                height: 45px;
                position: absolute;
                right: 7px;
                top: 5px;
                border-radius: 8px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        button {
            float: left;
            background-color: $successColor;
            color: #fff;
            font-family: "Poppins";
            border: 0;
            padding: 12px 22px;
            margin: 10px 0 12px 0;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .productListWrap {
        @include importantWl();
        margin: 30px 0 0 0;
        display: flex;
        flex-flow: column-reverse;
        .plBox {
            @include importantWl();
            background: #EAEFF2;
            border: 1px solid #DFE8EE;
            box-shadow: 10px 10px 38px rgba(209, 217, 230, 0.71), -9px -14.42px 41.17px #FFFFFF;
            border-radius: 16px;
            padding: 20px 35px;
            display: flex;
            align-items: center;
            margin: 13px 0;
            position: relative;
            overflow: hidden;
            picture {
                width: 100px;
                height: 90px;
                float: left;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            h4 {
                width: 185px;
                float: left;
                font-family: 'Poppins';
                margin: 0 0 0 30px;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 23px;
                color: #3D4E58;
                small {
                    @include importantWl();
                    color: #1C87EA;
                    margin: 6px 0 0 0;
                    font-size: 13px;
                }
            }
            h5.price {
                float: left;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 26px;
                color: #FB7272;
                margin: 0 0 0 40px;
                &.hide {
                    display: none;
                }
                &.show {
                    display: block;
                }
            }
            .delete {
                background-color: transparent;
                border: 0;
                outline: none;
                position: absolute;
                right: 32px;
                font-size: 20px;
                color: #c0ccd3;
                cursor: pointer;
            }
            a.edit {
                position: absolute;
                right: 82px;
                color: #c0ccd3;
                font-size: 19px;
            }
            .deleteplBoxcontain {
                position: absolute;
                z-index: 99;
                right: -280px;
                background: #fff;
                padding: 13px 23px;
                border-radius: 11px;
                display: flex;
                align-items: center;
                font-family: "Poppins";
                transition: all 500ms cubic-bezier(0.4, 0.5, 0.3, 1.25) 100ms;
                p {
                    width: auto;
                    float: left;
                    margin: 0 22px 0 0px;
                    font-size: 15px;
                }
                button {
                    border: 0;
                    outline: none;
                    cursor: pointer;
                    padding: 8px 12px;
                    margin: 0 3px;
                    font-family: "Poppins";
                    &.sureDelete {
                        background-color: $successColor;
                        color: #fff;
                    }
                }
                &.active {
                    right: 20px;
                }
            }
        }
    }
}


.cpwHead {
    @include importantWl();
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        float: left;
        margin: 0;
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        color: #000000;
    }
    .headbttnsWrp {
        float: left;
        display: flex;
        align-items: center;
        a.addBttn {
            float: left;
            width: 40px;
            height: 40px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            color: #717c98;
        }
        .searchBox {
            float: left;
            margin: 0 10px 0 0;
            .searchInpt {
                width: 300px;
                float: left;
                border: 0;
                outline: none;
                background-color: #fff;
                font-family: 'Poppins';
                height: 45px;
                padding: 0 0px 0 50px;
                border-radius: 25px;
                background-image: url(../images/searchIcon.svg);
                background-size: 23px;
                background-repeat: no-repeat;
                background-position: 14px 50%;
            }
        }
    }
}

.caregoriesListing {
    @include importantWl();
    margin: 30px 0 0 0;
    .cpwHead {
        margin: 0 0 25px 0;
    }
    .catBox {
        width: 100%;
        float: left;
        background-color: #fff;
        margin: 6px 6px;
        padding: 12px 85px 12px 20px;
        position: relative;
        h4 {
            @include importantWl();
            margin: 0 0;
            font-family: "Poppins";
            font-size: 15px;
            font-weight: 400;
            color: #000;
            text-transform: capitalize;
            b {
                font-weight: 500;
            }
            small {
                @include importantWl();
                margin: 10px 0 0 0;
                color: #9d9d9d;
            }
        }
        .delete {
            position: absolute;
            right: 11px;
            width: auto;
            padding: 0 0;
            background: #fff;
            border: 0;
            top: 15px;
            color: #b5bdc3;
            cursor: pointer;
        }
        .editIcon {
            position: absolute;
            right: 41px;
            top: 13px;
            a {
                color: #b5bdc3;
            }
        }
    }
}

.catBoxDeleteWrap {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    .confBox {
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        border: 1px solid #8993a5;
        padding: 45px 0;
        border-radius: 12px;
        width: 320px;
        h4 {
            @include importantWl();
            margin: 0;
        }
        span {
            @include importantWl();
            margin: 20px 0 0 0;
            button {
                display: inline-block;
                width: 75px;
                height: 38px;
                border: 0;
                margin: 0 6px;
                cursor: pointer;
                outline: none !important;
                &.sureToDelete {
                    background-color: $successColor;
                }
            }
        }
    }
}

.addCategories {
    @include importantWl();
    margin: 30px 0 0 0;
    .addCatForm {
        @include importantWl();
        margin: 50px 0 0 0;
        .addCategory {
            width: 39%;
            float: left;
            font-family: "Poppins";
            background: #eaeff2;
            border: 1px solid #d2d6d9;
            padding: 15px 25px;
            outline: none;
            border-radius: 9px;
            color: #000;
            font-weight: 400;
            font-size: 14px;
            margin: 0 8px 0 0px;
        }
        button {            
            font-family: "Poppins";
            background: $successColor;
            border: 1px solid $successColor;
            padding: 15px 25px;
            outline: none;
            border-radius: 9px;
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            float: left;
            cursor: pointer;
        }
    }
}

.catWithSubCatBox {
    @include importantWl();
    margin: 15px 0;
    h4 {
        @include importantWl();
        font-family: 'Poppins';
        margin: 0;
    }
    span.subCatbox {
        float: left;
        background-color: #fff;
        font-family: "Ubuntu";
        margin: 10px 10px 0 0;
        padding: 12px 65px 12px 16px;
        border-radius: 5px;
        font-size: 14px;
        color: #67727a;
        font-weight: 400;
        position: relative;
        button.delSubCat {
            padding: 0;
            border: 0;
            background-color: #fff;
            cursor: pointer;
            position: absolute;
            right: 17px;
            color: #909699;
            outline: none;
        }
    }
}

.editCatBox {
    @include importantWl();
    margin: 50px 0 0 0;
    .editCat {
        width: 300px;
        float: left;
        font-family: "Poppins";
        background: #eaeff2;
        border: 1px solid #d2d6d9;
        padding: 15px 25px;
        outline: none;
        border-radius: 9px;
        color: #000;
        font-weight: 400;
        font-size: 14px;
        margin: 0 8px 0 0px;
    }
    button {            
        font-family: "Poppins";
        background: $successColor;
        border: 1px solid $successColor;
        padding: 15px 25px;
        outline: none;
        border-radius: 9px;
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        float: left;
        cursor: pointer;
    }
}

.subcatLIstMainWrap {
    @include importantWl();
    .subCatBoxContain {
        @include importantWl();
        margin: 20px 0 0 0;
    }
    .subCatBox {        
        width: 100%;
        float: left;
        background-color: #fff;
        margin: 6px 0px;
        padding: 12px 85px 12px 20px;
        position: relative;
        font-family: 'Ubuntu';
        font-size: 14px;
        position: relative;
        .deleteIcon {
            padding: 0;
            position: absolute;
            right: 15px;
            top: 13px;
            border: 0;
            background: #fff;
            color: #bfc0cd;
            cursor: pointer;
        }
        .editSubcat {
            padding: 0;
            position: absolute;
            right: 40px;
            top: 13px;
            border: 0;
            background: #fff;
            color: #bfc0cd;
            cursor: pointer;
        }
    }
}
.changeCategory {
    width: 35%;
    float: left;
    font-family: "Poppins";
    background: #eaeff2;
    border: 1px solid #d2d6d9;
    padding: 15px 25px;
    outline: none;
    border-radius: 9px;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    margin: 0 8px 0 0px;
    text-transform: capitalize;
}

.storyListWrapAdmin {
    @include importantWl();
    margin: 12px 0 0 0;
    display: flex;
    flex-flow: row wrap;
    gap: 3.5%;
    .storyBox {
        width: 31%;
        height: 350px;
        background-color: #fff;
        margin: 20px 0 0 0;
        position: relative;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        video::-webkit-media-controls-volume-slider {
            display:none;
        }
            
        video::-webkit-media-controls-mute-button {
            display:none;
        }
        video::-webkit-media-controls-fullscreen-button {
            display: none;
        }
            
        video::-webkit-media-controls-play-button {
            display:none;
        }
        // video::-webkit-media-controls-current-time-display{
        //     display:none;
        // }
        a, button {
            position: absolute;
            z-index: 9;
            top: 0;
            right: 0;
            padding: 13px 10px;
            background: #fff;
            border-radius: 0px 0px 0px 17px;
            color: #008cff;
            &.delete {
                right: 37px;
            }
            &.edit {
                border-radius: 0;
            }
        }
        
    }
}

.OfferBttn {
    margin-left: 12px !important;
    background: #1c87ea !important;
}

.offerBoxWrap {
    @include importantWl();
    margin: 20px 0;
    padding: 35px;
    background: #EAEFF2;
    border: 1px solid #DFE8EE;
    box-shadow: 10px 10px 38px rgb(209 217 230 / 71%), -9px -14.42px 41.17px #ffffff;
    border-radius: 16px;
    p {
        float: left;
        font-family: "Ubuntu";
        cursor: pointer;
        color: #f37171;
        font-weight: 600;
        font-size: 14px;
        margin: 12px 0 0 0;
    }
    h2 {
        @include importantWl();
        font-family: 'Poppins';
        margin: 0 0 4px 0;
        font-weight: 400;
        font-size: 19px;
    }
    .offerImg {
        @include importantWl();
        position: relative;
        display: flex;
        align-items: center;
        margin: 20px 0;
        input {
            display: none;
        }
        img {
            width: 90px;
            height: 90px;
            object-fit: contain;
            background-image: url(../images/noImage.png);
            background-repeat: no-repeat;
            background-size: 45px;
            background-position: center;
            margin: 0 13px 0 0px;
            border-radius: 100%;
            border: 1px solid #1C87EA;
        }
        b {
            font-family: 'Ubuntu';
            color: #1C87EA;
            cursor: pointer;
        }
    }
}

.completeOrderListWrap {
    @include importantWl();
    margin: 30px 0;
    .ordersListBox {
        @include importantWl();
        background-color: #fff;
        padding: 20px 20px 60px 20px;
        margin: 8px 0;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        position: relative;
        h4 {
            max-width: 300px;
            float: left;
            font-family: "Poppins";
            margin: 0px 9px 0 0px;
            font-size: 14px;
            font-weight: 500;
            small {
                @include importantWl();
                color: #0040ff;
                margin: 5px 0 0 0;
            }
            strong {
                @include importantWl();
                font-weight: 400;
                margin: 9px 0 0 0;
                color: #ababab;
            }
        }
        h5 {
            float: left;
            margin: 0 0 0 0;
            font-family: "Poppins";
            color: #6bab47;
            font-weight: 500;
            small {
                @include importantWl();
                color: #f77;
            }
        }
        a.bttnMain {
            position: absolute;
            right: 24px;
            text-decoration: none;
            color: #ffa800;
            font-size: 23px;
        }
        .orderTime {
            width: 100%;
            text-align: center;
            font-family: "Ubuntu";
            position: absolute;
            bottom: 16px;
            font-size: 12px;
            color: #989898;
            border-top: 1px solid #ddd;
            right: 0;
            padding: 14px 0 0 0;
            font-weight: 500;
        }
    }
}



.orderDetailsCompleteBlog {
    @include importantWl();
    margin: 25px 0 0 0;
    .detailsPart {
        @include importantWl();
        p {
            @include importantWl();
            margin: 3px 0;
            font-family: 'Poppins';
            display: flex;
            align-items: center;
            font-size: 14px;
            svg {
                margin: 0 11px 0 0px;
                color: #686cc4;
                width: 19px;
            }
        }
    }
}

.productList {
    @include importantWl();
    margin: 20px 0 0px 0;
    .productBox {
        @include importantWl();
        padding: 20px 20px;
        background-color: #fff;
        margin: 6px 0;
        display: flex;
        align-items: center;
        position: relative;
        picture {
            width: 60px;
            height: 60px;
            float: left;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
        h4 {
            float: left;
            margin: 0 0 0 45px;
            font-family: "Ubuntu";
            display: flex;
            flex-flow: column;
            font-size: 17px;
            font-weight: 400;
            small {                
                margin: 10px 0px 0 0;
                font-weight: 500;
                color: #86c264;
                font-size: 17px;
            }
        }
        a.LiBttn {
            position: absolute;
            right: 36px;
            font-size: 24px;
            color: #ffa800;
        }
    }
}

.priceSection {
    @include importantWl();
    display: flex;
    justify-content: space-between;
    font-family: 'Poppins';
    margin: 10px 0 35px 0;
    padding: 0 16px;
    p {
        font-size: 14px;
        font-weight: 500;
    }
}

#react-select-3-listbox {
    color: #000 !important;
    font-size: 20px !important;
}